import "98.css";
import Draggable from "react-draggable";
import "./FakeWindow.scss";

type FakeWindowProps = {
    visible: boolean;
    onCloseWindow: () => void;
    children: JSX.Element;
    title: string;
    size: {width: number, height: number};
    startpos: {x: number, y: number};
}

function FakeWindow(props: FakeWindowProps)
{
    return (props.visible? <>
        <Draggable
            handle=".title-bar-handle"
        >
            <div style={{ 
                position: "absolute", 
                width: props.size.width, 
                height: props.size.height, 
                maxWidth: "95vw", 
                maxHeight: "95vh",
                top: props.startpos.y,
                left: props.startpos.x
            }} className="window">
                <div className="title-bar">
                    <div className="title-bar-handle"></div>
                    <div className="title-bar-text">{props.title}</div>
                    <div className="title-bar-controls">
                        <button aria-label="Close" onClick={props.onCloseWindow}/>
                    </div>
                </div>

                <div className="window-body" style={{height: '88%'}}>
                    {props.children}
                </div>
            </div>
        </Draggable>
    </> : null)
}

export default FakeWindow;