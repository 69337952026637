import React, { useEffect, useState } from 'react';
import FakeFile from './components/FakeFile';
import ZipFileIcon from './resources/ZipFile.png';
import Taskbar from './components/Taskbar';
import FakeWindow from './components/FakeWindow';
import ReactPlayer from 'react-player';

function App() {
  const [links, setLinks] = useState<any | undefined>(undefined);
  const [windows, setWindows] = useState<Array<{title:string, video:string, key:string, x: number, y: number}>>([]);

  function addWindow (){
    if(links !== undefined)
    {
      console.log("click");
      let list = links.links;
      let selected = list[Math.floor(Math.random()*list.length)];
      let newWindows = [];
      windows.forEach(window => {
        newWindows.push(window);
      });
      let newX = Math.random() * ((window.visualViewport?.width ?? 0) - 500);
      let newY = Math.random()  * ((window.visualViewport?.height ?? 0) - 300);
      newWindows.push({title: selected.title, video: selected.video, key: Math.random().toString(), x: newX, y: newY});
      setWindows(newWindows);
    }
  };

  useEffect(() => {
    if(!links){
      fetch('links.json').then(response => {
        response.json().then(fetchLinks => {
          setLinks(fetchLinks);
        })
      });
    }
  }, [setLinks, links]);

  function closeLink(key: string)
  {
    let newWindows = windows.filter((element, index) => {
      return element.key !== key;
    })
    setWindows(newWindows);
  }

  return (
    <div className="App">
      <FakeFile filename='nudes.zip' startpos={{x: 0, y: 0}} icon={ZipFileIcon} onFileClick={addWindow}/>
      <Taskbar/>
      {windows.map(vid => {
        return (<>
          <FakeWindow
            title={vid.title}
            visible={true}
            onCloseWindow={() => {closeLink(vid.key)}}
            size={{width: 500, height: 300}}
            key={vid.key}
            startpos={{x: vid.x, y: vid.y}}
          >
            <ReactPlayer url={vid.video} width="100%" height="100%" playing={true} loop={true} />
          </FakeWindow>
        </>);
      })}
    </div>
  );
}

export default App;
