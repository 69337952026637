import React, { useState } from "react";
import "./FakeFile.scss";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";

type FakeFileProps = {
    filename: string;
    startpos: {x: number, y: number};
    icon: string;
    onFileClick: () => void;
}

function FakeFile(props: FakeFileProps)
{
    const [dragging, setDragging] = useState(false);

    function onStop()
    {
        if(!dragging)
        {
            props.onFileClick();
        }
        setDragging(false);
    }

    function onDrag(e: DraggableEvent, data: DraggableData) : void | false
    {
        if(data.lastX !== data.x || data.lastY !== data.y)
        {
            setDragging(true);
        }
    }

    return (<>
        <Draggable
            defaultPosition={props.startpos}
            onDrag={onDrag}
            onStop={onStop}
        >
            <div className="fakefile" style={{backgroundImage: `url('${props.icon}'`}}>
                {props.filename}
            </div>
        </Draggable>
    </>)
}

export default FakeFile;