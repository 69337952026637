import React, { useEffect, useState } from 'react';
import "./Taskbar.scss";

function Taskbar()
{
    const [time, setTime] = useState('0:00 AM');

    function updateClock()
    {
        let date = new Date();
        let hours = date.getHours();
        let minutes : any = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        setTime(strTime);
    }

    useEffect(() => {
        updateClock();
        const interval = setInterval(() => {
          updateClock();
        }, 1000);
        return () => clearInterval(interval);
      }, []);

    return (<>
        <div className='taskbar'>
            <a href="https://unlock.lol/"><div className="start"></div></a>
            <div className="clock">{time}</div>
        </div>
    </>)
}

export default Taskbar;